var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3ee6a8ac80231c4012698eedf1c4a8cb55d04cc6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";

// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-initialization-config-files

const BUILD_ENV = process.env.BUILD_ENV;
const NODE_ENV = process.env.NODE_ENV;
const environment = NODE_ENV || BUILD_ENV || "local";

const SENTRY_ENV = process.env.SENTRY_ENV || environment;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  attachStacktrace: true,
  debug: environment === "development" && !process.env.SENTRY_DISABLE_DEBUG,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  maxBreadcrumbs: 100,
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
});
