import { BASE_URL_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE } from "./constants";
import {
  DataType,
  IDataFetcherOptions,
  IDataFetcherResponse,
  IDataFetcherResponseError,
  IObjectKey,
  NormalizedQueryKey,
} from "./types";
import { ParsedUrlQuery } from "querystring";
import { captureException } from "@sentry/nextjs";

export async function dataFetcher(
  url: string,
  options?: IDataFetcherOptions,
): Promise<IDataFetcherResponse> {
  try {
    // body
    // from `object` to `string`
    const body: string | undefined = options?.body
      ? JSON.stringify(options.body)
      : undefined;

    // request
    const response = await request(url, options?.method, body);
    const data = await parseResponse(response);

    // valid response
    if (response?.ok) {
      return {
        success: true,
        error: null,
        data,
        url: response.url,
      };
    }
    // throw error
    // with status as string
    else {
      const error = formatError(data || {}, response);

      // is it trying to load the account details?
      const isNotLoggedIn = !!(
        url.indexOf("api/v2/account") > -1 &&
        (options?.method === "GET" || options?.method === undefined) &&
        error.message === "Not Found"
      );
      if (!isNotLoggedIn) {
        if (process.env.NODE_ENV === "development") {
          console.error(`data, dataFetcher error for ${url}: ${error.message}`);
        }

        // Capture exception with Sentry
        captureException(new Error(`${error.status} - ${error.message}`));
      }

      return {
        success: false,
        error,
        data: null,
        url: response.url,
      };
    }
  } catch (error: any) {
    // Capture error with Sentry
    captureException(error);

    return {
      success: false,
      error: { message: error.message, status: 400 },
      data: null,
      url,
    };
  }
}

export async function parseResponse(response: Response): Promise<DataType> {
  const text: string | null = response?.text ? await response.text() : null;
  if (text && text.length > 0) {
    try {
      return JSON.parse(text);
    } catch (error) {
      if (response.ok) {
        return String(text);
      } else {
        return { error: String(text) };
      }
    }
  }

  return null;
}

export function formatError(
  data: DataType,
  response: Response,
): IDataFetcherResponseError {
  if (data) {
    // object
    if (typeof data === "object") {
      // error
      if (data.error) {
        // number
        if (typeof data.error === "number") {
          return {
            data,
            message: String(data.error),
            status: data.error,
          };
        }
        // string
        else if (typeof data.error === "string") {
          return {
            data,
            message: data.error,
            status: response.status,
          };
        }
        // object
        else if (typeof data.error === "object" && data.error["message"]) {
          return {
            data,
            message: data.error["message"],
            status: response.status,
          };
        }
      }
      // errors
      else if (data.errors) {
        const errors =
          typeof data.errors === "object"
            ? data.errors
            : JSON.parse(data.errors);
        const firstError = Object.values(errors)[0] || DEFAULT_ERROR_MESSAGE;

        const message =
          Array.isArray(firstError) && firstError.length > 0
            ? `${Object.keys(errors)[0]}: ${firstError[0]}`
            : typeof firstError === "object" &&
              Object.keys(firstError).length > 0
            ? `${Object.keys(firstError)[0]}: ${Object.values(firstError)[0]}`
            : String(firstError);

        return {
          data: { errors },
          message,
          status: response.status,
        };
      }
      // other keys
      else if (Object.keys(data).length > 0) {
        const [rawMessage] = Object.values(data);
        const message =
          rawMessage && typeof rawMessage === "string"
            ? rawMessage
            : DEFAULT_ERROR_MESSAGE;

        return {
          data,
          message,
          status: response.status,
        };
      }
    }

    // string
    else if (typeof data === "string") {
      if (data.length > 0) {
        return {
          data: {
            error: data,
          },
          message: data,
          status: response.status,
        };
      }
    }
    // number
    else if (typeof data === "number") {
      return {
        data: {
          error: data,
        },
        message: String(data),
        status: data,
      };
    }
  }

  // empty
  return { message: DEFAULT_ERROR_MESSAGE, status: response.status };
}

// Status
// 400 = bad request
// 404 = not found
// 409 = conflict

// base request to API
function request(
  url: RequestInfo | URL,
  method = "GET",
  body?: BodyInit | null | undefined,
): Promise<Response> {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    credentials: "include",
    method,
    body,
  });
}

export function normalizeQueryKey(
  key: string | IObjectKey,
): NormalizedQueryKey {
  if (typeof key === "string") {
    return key;
  }

  const { url, method = "GET", params = {} } = key;

  return [url, { url, method, params }];
}

function removeTrailingSlash(path: string): string {
  if (path.length > 1 && path[path.length - 1] === "/") {
    return path.slice(0, -1);
  }

  return path;
}

function ensureLeadingSlash(path: string): string {
  if (path[0] === "/") {
    return path;
  }

  return "/" + path;
}

function isUrlAbsolute(url) {
  return url.indexOf("://") > 0 || url.indexOf("//") === 0;
}

interface getAbsoluteUrlPropsOptions {
  baseUrl?: string;
  params?: ParsedUrlQuery;
}

export function getAbsoluteUrl(
  url: string,
  { baseUrl, params }: getAbsoluteUrlPropsOptions = {},
): string {
  const urlIsAbsolute = isUrlAbsolute(url);

  if (urlIsAbsolute) {
    return stripParams(url) + getParams(url, params);
  }

  if (baseUrl && isUrlAbsolute(baseUrl)) {
    const absoluteUrl = removeTrailingSlash(baseUrl) + ensureLeadingSlash(url);
    return getAbsoluteUrl(absoluteUrl, { params });
  }

  // Throw an error if:
  // - url is not absolute
  // - baseUrl is not defined
  if (!baseUrl) {
    throw new Error(BASE_URL_ERROR_MESSAGE);
  }

  // When `baseUrl` is a relative path
  const formattedBaseUrl =
    baseUrl !== "/" ? ensureLeadingSlash(removeTrailingSlash(baseUrl)) : "";

  // TEMP
  const absoluteUrl =
    (typeof window !== "undefined" ? window.location.origin : "") +
    formattedBaseUrl +
    ensureLeadingSlash(url);

  return stripParams(absoluteUrl) + getParams(absoluteUrl, params);
}

function stripParams(absoluteUrl: string): string {
  // TEMP
  try {
    const url = new URL(absoluteUrl);
    return url.origin + url.pathname;
  } catch (err) {
    console.error(err);
  }

  return absoluteUrl;
}

function getParams(absoluteUrl: string, params: ParsedUrlQuery = {}): string {
  if (params) {
    let originalParams = {};

    // TEMP
    try {
      originalParams = Object.fromEntries(
        new URLSearchParams(new URL(absoluteUrl).search).entries(),
      );
    } catch (err) {
      console.error(err);
    }

    // TODO: Fix the type. I'm amazed by how TypeScript type `URLSearchParams` as if
    // it accepts object that only have a string as value, but it does actually works
    // with array, number, and other types too, so I just cast the type and get over it.
    const mergedParams = {
      ...originalParams,
      ...params,
    } as unknown as Record<string, string>;
    const urlSearchParams = new URLSearchParams(mergedParams);

    if (urlSearchParams.toString()) {
      return `?${urlSearchParams.toString()}`;
    }
  }

  return "";
}
